import { Injectable } from '@angular/core';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { ApiService } from '@Services/api.service';
import { ICodeDescription, ISearchResponse2 } from '@Shared/models/index.model';
import { IContentSearchItem } from 'projects/evolutics-admin-ui/src/app/client-libraries/client-libraries-comp/client-library-layout/client-library-layout.service';
import { HttpHeaders } from '@angular/common/http';
import { Config } from '@configs/index.config';

@Injectable({
  providedIn: 'root',
})
export class FaqAdminService {
  baseUrl = environment.apiBaseUrl + '/admin/rest/';
  constructor(public apiService: ApiService) {}

  getAllMenus() {
    return this.apiService.get<any>(this.baseUrl + 'faq/all');
  }
  createFaqs(payload: any) {
    return this.apiService.post(this.baseUrl + 'faq', payload);
  }
  /**
   * Update FAQ Documentation
   * @param id Id of the record
   * @param payload Data to be updated
   * @returns Updated record
   */
  updateFaqs(id, payload: any) {
    return this.apiService.put(this.baseUrl + 'faq/' + id, payload);
  }
  getMenuById(id: any) {
    return this.apiService.get(this.baseUrl + 'faq/menu/' + id);
  }
  searchFAQ(query: any) {
    return this.apiService.get<ISearchResponse2<IContentSearchItem>>(
      this.baseUrl + 'faq/search',
      query
    );
  }
  searchFAQWithCache(query: any) {
    // debugger;
    return this.apiService.getWithLocalCache(this.baseUrl + 'faq/search', query, Config.headerForJupiterCrossPlatformRequest);
  }

  /**
   * search faq index with payload
   * @param query query payload
   * @returns observable of list
   */
  searchFAQIndex(query: any) {
    return this.apiService.get<any[]>(this.baseUrl + 'faq/index/search', query);
  }
}
